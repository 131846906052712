
import { defineComponent, ref } from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import * as Yup from "yup";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { Actions } from "@/store/enums/StoreEnums";
import Swal from "sweetalert2/dist/sweetalert2.min.js";

export default defineComponent({
    name: "sign-up",
    components: {
        Field,
        Form,
        ErrorMessage,
    },
    setup() {
        const store = useStore();
        const router = useRouter();

        const submitButton = ref<HTMLElement | null>(null);

        const registration = Yup.object().shape({
            name: Yup.string().required().label("First Name"),
            surname: Yup.string().required().label("Second Name"),
            email: Yup.string().min(4).required().email().label("Email"),
            password: Yup.string().min(4).required().label("Password"),
            cpassword: Yup.string()
                .min(4)
                .required()
                .oneOf([Yup.ref("password"), null], "Passwords must match")
                .label("Password Confirmation"),
        });

        const onSubmitRegister = (values) => {
            // Clear existing errors
            store.dispatch(Actions.LOGOUT);

            // Activate indicator
            submitButton.value?.setAttribute("data-kt-indicator", "on");

            // Dummy delay
            setTimeout(() => {
                // Send login request
                store
                    .dispatch(Actions.REGISTER, values)
                    .then(() => {
                        Swal.fire({
                            text: "All is cool! Now you submit this form",
                            icon: "success",
                            buttonsStyling: false,
                            confirmButtonText: "Ok, got it!",
                            customClass: {
                                confirmButton: "btn fw-bold btn-light-primary",
                            },
                        }).then(function () {
                            // Go to page after successfully login
                            router.push({ name: "dashboard" });
                        });
                    })
                    .catch(() => {
                        Swal.fire({
                            text: store.getters.getErrors[0],
                            icon: "error",
                            buttonsStyling: false,
                            confirmButtonText: "Try again!",
                            customClass: {
                                confirmButton: "btn fw-bold btn-light-danger",
                            },
                        });
                    });

                submitButton.value?.removeAttribute("data-kt-indicator");
            }, 2000);
        };

        return {
            registration,
            onSubmitRegister,
            submitButton,
        };
    },
});
